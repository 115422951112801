.TextAreaVerificationInput .inner-container {
    width: 100%;
}

.TextAreaVerificationInput textarea {
    padding: 12.75px;
    width: calc(100% - 25.5px);
    height: 100px;
    font-size: 17px;
    font-weight: 300;
    background-color: #FAFAFA;
    border: 1px solid #EAEAEA;
    border-radius: 2px;
    transition: 0.3s;
    margin-bottom: 5px;
}

.TextAreaVerificationInput textarea:focus {
    background-color: #FFFFFF;
}

.TextAreaVerificationInput .has-error textarea {
    border: 1px solid #cc0000;
}

.TextAreaVerificationInput span {
    height: 0;
    opacity: 0;
    transition: 0.3s
}

.TextAreaVerificationInput .has-error span {
    color: #cc0000;
    font-weight: 300;
    opacity: 1;
    height: auto;
}