.Imprint {
    padding-top: 20px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 186.5px - 74px - 42.5px - 70px);
}

.Imprint .ImprintInner {
    max-width: 1200px;
    width: 100%;
}

.Imprint .content-container {
    display: flex;
    margin-top: 50px;
}

.Imprint .german-container {
    width: 50%;
    margin-right: 40px;
    text-align: end;
}

.Imprint .english-container {
    width: 50%;
    margin-left: 40px;
}

.Imprint h2 {
    margin-top: 70px;
}

.copyright-hint {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    margin-top: 60px;
    text-align: center;
}

@media (max-width: 650px) {
    .Imprint .content-container {
        flex-direction: column;
    }

    .Imprint .german-container {
        width: 100%;
        margin-right: 0;
        margin-bottom: 80px;
        text-align: start;
    }

    .Imprint .english-container {
        width: 100%;
        margin-left: 0;
    }
}