.Paintings {
    padding-top: 20px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 186.5px - 74px - 42.5px - 70px);
}

.Paintings .PaintingsInner {
    max-width: 1200px;
    width: 100%;
}

.Paintings .color-selector-container {
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
    margin-bottom: 30px;
}

.Paintings .color-selector {
    width: 250px;
}

.Paintings .top-text {
    font-weight: 300;
    text-align: center;
    padding: 0px 50px;
}

.Paintings .top-text-container {
    margin-bottom: 30px;
}

@media (max-width: 1000px) {
    .Paintings .color-selector-container {
        margin-right: 30px;
    }

    .Paintings .top-text {
        padding: 0px 30px;
    }
}

@media (max-width: 800px) {
    .Paintings .color-selector-container {
        margin-right: 15px;
    }

    .Paintings .top-text {
        padding: 0px 15px;
    }
}

@media (max-width: 650px) {
    .Paintings .color-selector-container {
        margin-left: 15px;
    }

    .Paintings .color-selector {
        width: 100%;
    }
}