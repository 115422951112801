.App {
  padding: 0 1.5rem;
}

.custom-row {
  display: flex;
  flex-wrap: wrap;
}

.custom-col {
  width: 33.33333%;
  margin-bottom: 50px;
}

.custom-two-col {
  width: 50%;
  margin-bottom: 50px;
}

@media (max-width: 650px) {
  .custom-row {
    flex-direction: column;
  }

  .custom-col {
    width: 100%;
  }

  .custom-two-col {
    width: 100%;
  }
}



.text-link {
  text-decoration: none;
  /* color: #000000; */
  color: var(--green-color);
  font-weight: 500;
}