.About {
    padding-top: 20px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 186.5px - 74px - 42.5px - 70px);
}

.About .AboutInner {
    max-width: 1200px;
    width: 100%;
}

.About .image-container {
    display: flex;
    justify-content: center;
}

.About .image-container img {
    width: 200px;
    height: 200px;
}

.About .image-container {
    display: flex;
    justify-content: center;
}

.About .content-container {
    display: flex;
    margin-top: 50px;
}

.About .german-container {
    width: 50%;
    margin-right: 40px;
}

.About .english-container {
    width: 50%;
    margin-left: 40px;
}

@media (max-width: 650px) {
    .About .content-container {
        flex-direction: column;
    }

    .About .german-container {
        width: 100%;
        margin-right: 0;
        margin-bottom: 40px;
    }

    .About .english-container {
        width: 100%;
        margin-left: 0;
    }
}