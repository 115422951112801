.PrivacyPolicy {
    padding-top: 20px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 186.5px - 74px - 42.5px - 70px);
}

.PrivacyPolicy .PrivacyPolicyInner {
    max-width: 1200px;
    width: 100%;
}

.PrivacyPolicy .content-container {
    display: flex;
    margin-top: 50px;
}

.PrivacyPolicy .german-container {
    width: 50%;
    margin-right: 40px;
    text-align: end;
}

.PrivacyPolicy .english-container {
    width: 50%;
    margin-left: 40px;
}

.PrivacyPolicy .matomo-iframe {
    border: 0;
    width: 100%;
}

@media (max-width: 650px) {
    .PrivacyPolicy .content-container {
        flex-direction: column;
    }

    .PrivacyPolicy .german-container {
        width: 100%;
        margin-right: 0;
        margin-bottom: 80px;
        text-align: start;
    }

    .PrivacyPolicy .english-container {
        width: 100%;
        margin-left: 0;
    }
}