.Navigation {
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Navigation .NavigationInner {
    max-width: 648px;
    width: 100%;
    /* background-color: rgba(0, 0, 0, 0.4); */
    text-align: center;
}

.Navigation .nav-title {
    font-weight: 300;
    font-size: 44px;
    letter-spacing: 10px;
    text-transform: uppercase;
}

.Navigation .nav-title-art {
    font-weight: 900;
    color: var(--first-color);
}

.Navigation .nav-link {
    color: var(--first-color);
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 900;
    transition: 0.3s;
}

.Navigation .nav-link:hover {
    color: var(--second-color);
}

.Navigation .nav-link-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 4px;
    margin-top: 14px;
}

.Navigation .active-link {
    color: var(--second-color);
}

/* Begin of Mobile */
.Navigation .mobile-nav-container {
    display: flex;
}

.Navigation .mobile-left-nav-element {
    width: 15%;
}

.Navigation .mobile-middle-nav-element {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
}

.Navigation .mobile-right-nav-element {
    width: 15%;
    display: flex;
    justify-content: flex-end;
}

.Navigation .mobile-nav-title {
    font-weight: 300;
    font-size: 18.25px;
    text-transform: uppercase;
}

.Navigation .mobile-nav-title-art {
    font-weight: 900;
    color: var(--first-color);
}

.Navigation .mobile-menu-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: var(--green-color);
    width: 43.25px;
    height: 43.25px;
    border-radius: 2px;
    cursor: pointer;
}

.Navigation .mobile-menu-button img {
    width: 20px;
    height: 20px;

}

.Navigation .mobile-navbar {
    position: fixed;
    top: 0;
    right: -16px;
    width: 0;
    height: 100vh;
    background-color: #1F2325;
    padding: .5rem 1rem 0 0;
    transition: .5s;
    z-index: 100;
}

.Navigation .mobile-active-nav .mobile-navbar {
    right: 0px;
    width: 200px;
}

.Navigation .mobile-nav-content {
    overflow: hidden;
}

.Navigation .mobile-close-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    margin-bottom: 30px;
}

.Navigation .mobile-close-image {
    width: 17px;
    height: 17px;
    cursor: pointer;
}

.Navigation .mobile-link-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Navigation .mobile-nav-link {
    text-decoration: none;
    color: #FFFFFF;
    font-weight: 300;
    font-size: 14px;
    text-transform: uppercase;
    transition: 0.3s;
    padding: 20px;
}

.Navigation .mobile-nav-link:hover {
    color: #ABC04D;
}

.Navigation .mobile-active-link {
    color: #ABC04D;
}

/* .Navigation .mobile-nav-dark-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
    transition: opacity 0.5s;
    background-color: rgba(0, 0, 0, 0.4);

} */

/* .Navigation .mobile-active-nav .mobile-nav-dark-background {
    opacity: 1;
    width: 100vw;
    height: 100vh;
} */

.Navigation .mobile-nav-dark-background {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
}

.Navigation .mobile-nav-close-hide {
    animation: hide-animate 0.5s ease-in-out forwards;
}

.Navigation .mobile-nav-close-show {
    animation: show-animate 0.5s ease-in-out forwards;
}

@keyframes hide-animate {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        display: none !important;
    }
}

@keyframes show-animate {
    0% {
        opacity: 0;
        display: block !important;
    }

    100% {
        opacity: 1;

    }
}

@media (max-width: 767px) {
    .Navigation {
        padding-top: 30px;
    }
}