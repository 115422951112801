/* @import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;600;700;900&display=swap"); */

@font-face {
  font-family: "Lato";
  src: url("./static/fonts/Lato/Lato-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./static/fonts/Lato/Lato-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./static/fonts/Lato/Lato-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./static/fonts/Lato/Lato-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./static/fonts/Lato/Lato-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./static/fonts/Lato/Lato-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./static/fonts/Lato/Lato-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./static/fonts/Lato/Lato-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./static/fonts/Lato/Lato-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./static/fonts/Lato/Lato-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

body {
  margin: 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --first-color: #afafaf;
  --second-color: #797979;
  --green-color: #abc04d;
  --body-font: 'Lato', sans-serif;
  --normal-font-size: 1rem;
}

p {
  line-height: 1.75;
  font-weight: 300;
  margin-bottom: 25px;
}

li {
  line-height: 1.75;
  font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--first-color);
  font-weight: 900;
}

strong {
  color: var(--first-color) !important;
  font-weight: 900 !important;
}



input,
select,
textarea,
button {
  font-family: inherit;
}