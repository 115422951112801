.Footer {
    margin-top: 50px;
}

.Footer .upper-outer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #d7d7d7;
}

.Footer .upper-container {
    max-width: 648px;
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
}

.Footer .nav-link {
    color: var(--first-color);
    text-decoration: none;
    /* text-transform: uppercase; */
    font-weight: 900;
    padding: 0 20px;
    transition: 0.3s;
}

.Footer .nav-link:hover {
    color: var(--second-color);
}

.Footer .right-container {
    display: flex;
    align-items: flex-end;
}

.Footer .right-container a {
    margin-right: 20px;
}

.Footer .right-container a:hover {
    color: var(--green-color);
}

/* .Footer .right-container:hover {
    color: var(--green-color);
} */

.Footer .right-container svg {
    width: 18px;
    height: 18px;
    cursor: pointer;
    /* fill: var(--first-color); */
    fill: #2c2927;
    transition: 0.3s;
}

.Footer .right-container svg:hover {
    fill: var(--green-color);
}

.Footer .footer-copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #A5A5A5;
    padding: 30px 0;
    font-size: 11.8px;
    font-weight: 300;
}