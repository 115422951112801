.PaintingCard {
    display: flex;
    flex-direction: column;

    /* height: 100%;
    width: 100%; */

    padding: 0 50px;
}

.PaintingCard .painting-container {
    display: flex;
    align-items: flex-end;
    width: 100%;
    aspect-ratio: 1;
    max-width: 400px;
}

.PaintingCard .painting-image {
    max-width: 400px;
    max-height: 400px;
    width: 100%;
    height: 100%;
}

/* .PaintingCard .percent100 {
    width: 100%;
    height: 100%;
}

.PaintingCard .percent90 {
    width: 90%;
    height: 90%;
}

.PaintingCard .percent85 {
    width: 85%;
    height: 85%;
}

.PaintingCard .percent80 {
    width: 80%;
    height: 80%;
}

.PaintingCard .percent75 {
    width: 75%;
    height: 75%;
}

.PaintingCard .percent675 {
    width: 67.5%;
    height: 67.5%;
} */

.PaintingCard .text-container {
    max-width: 400px;
    width: 100%;
    margin-top: 10px;
}

.PaintingCard .card-header-text {
    font-size: 14px;
    font-weight: 900;
    color: var(--first-color);
}

.PaintingCard .card-cursive-text {
    font-size: 14px;
    font-weight: 300;
    color: #000000;
    font-style: italic;
}

.PaintingCard .card-normal-text {
    font-size: 14px;
    font-weight: 300;
    color: #000000;
}

.PaintingCard .text-wrapper {
    margin-bottom: 3px;
}

@media (max-width: 1000px) {
    .PaintingCard {
        padding: 0 30px;
    }
}

@media (max-width: 800px) {
    .PaintingCard {
        padding: 0 15px;
    }
}

@media (max-width: 650px) {
    .PaintingCard {
        align-items: center;
    }
}