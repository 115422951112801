.IconLoader {
    display: flex;
    align-items: center;
    justify-content: center;
    animation: logoanimation 4s ease-in-out infinite;
}

.IconLoader .title {
    font-weight: 300;
    font-size: 14px;
    text-transform: uppercase;
}

.IconLoader .title-art {
    font-weight: 900;
    color: var(--first-color);
}

@keyframes logoanimation {
    0% {
        -ms-transform: scale(0.9);
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }

    25% {
        -ms-transform: scale(1);
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -ms-transform: scale(0.9);
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }

    75% {
        -ms-transform: scale(1);
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    100% {
        -ms-transform: scale(0.9);
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
}