.Home {
    padding-top: 20px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 186.5px - 74px - 42.5px - 70px);
}

.Home .HomeInner {
    max-width: 1200px;
    width: 100%;
    /* max-width: 648px; */

    /* background-color: rgba(0, 0, 0, 0.4); */
    /* text-align: center; */
}

.Home .main-image-container {
    max-width: 648px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 80px;
}

.Home img {
    width: 100%;
    height: auto;
}