.NewsletterUnsubscribe {
    padding-top: 20px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 186.5px - 74px - 42.5px - 70px);
}

.NewsletterUnsubscribe .NewsletterUnsubscribeInner {
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.NewsletterUnsubscribe .top-text {
    font-weight: 300;
    margin-bottom: 30px;
}

.NewsletterUnsubscribe .signup-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 17px 0;
    width: 100%;
    max-width: 320px;
    font-size: 17px;
    color: var(--first-color);
    font-weight: 900;
    background-color: #FFFFFF;
    border: 2px solid #AFAFAF;
    margin-top: 40px;
    margin-bottom: 20px;
    transition: 0.3s;
    cursor: pointer;
}

.NewsletterUnsubscribe .signup-button:hover {
    color: #797979;
    border: 2px solid #797979;
}

.NewsletterUnsubscribe .wrong-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #cc0000;
}

.NewsletterUnsubscribe .loading-error-container {
    margin-top: 20px;
}

.NewsletterUnsubscribe .success-text {
    font-weight: 300;
    text-align: center;
}