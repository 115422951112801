.HomePaintingCardTwo {
    display: flex;
    flex-direction: column;

    padding: 0 30px;
}

.HomePaintingCardTwo .painting-container {
    display: flex;
    flex-direction: column;
    /* max-height: 350px;
    height: 100%; */
    /* align-items: flex-end; */
    /* width: 100%; */
    /* aspect-ratio: 1; */
    /* position: relative; */
}

.HomePaintingCardTwo .append-right {
    align-items: flex-end;
}

.HomePaintingCardTwo .real-painintg-container {
    aspect-ratio: 1;
    max-width: 350px;
    width: 100%;
}

.HomePaintingCardTwo .painting-image {
    max-width: 350px;
    max-height: 350px;
    width: 100%;
    height: 100%;
}

.HomePaintingCardTwo .painting-name {
    font-size: 14px;
    font-weight: 900;
    color: var(--first-color);
    margin-top: 10px;
}

@media (max-width: 650px) {
    .HomePaintingCardTwo {
        align-items: center;
        padding: 0 15px;
    }

    .HomePaintingCardTwo .append-right {
        align-items: flex-start;
    }

    .HomePaintingCardTwo .painting-container {
        max-width: 350px;
        width: 100%;
    }
}